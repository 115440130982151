exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-index-js": () => import("./../../../src/pages/components/index.js" /* webpackChunkName: "component---src-pages-components-index-js" */),
  "component---src-pages-development-index-js": () => import("./../../../src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foundations-accessibility-js": () => import("./../../../src/pages/foundations/accessibility.js" /* webpackChunkName: "component---src-pages-foundations-accessibility-js" */),
  "component---src-pages-foundations-colors-js": () => import("./../../../src/pages/foundations/colors.js" /* webpackChunkName: "component---src-pages-foundations-colors-js" */),
  "component---src-pages-foundations-design-principles-js": () => import("./../../../src/pages/foundations/design-principles.js" /* webpackChunkName: "component---src-pages-foundations-design-principles-js" */),
  "component---src-pages-foundations-design-tokens-js": () => import("./../../../src/pages/foundations/design-tokens.js" /* webpackChunkName: "component---src-pages-foundations-design-tokens-js" */),
  "component---src-pages-foundations-icons-js": () => import("./../../../src/pages/foundations/icons.js" /* webpackChunkName: "component---src-pages-foundations-icons-js" */),
  "component---src-pages-foundations-index-js": () => import("./../../../src/pages/foundations/index.js" /* webpackChunkName: "component---src-pages-foundations-index-js" */),
  "component---src-pages-foundations-layout-js": () => import("./../../../src/pages/foundations/layout.js" /* webpackChunkName: "component---src-pages-foundations-layout-js" */),
  "component---src-pages-foundations-logo-js": () => import("./../../../src/pages/foundations/logo.js" /* webpackChunkName: "component---src-pages-foundations-logo-js" */),
  "component---src-pages-foundations-typography-js": () => import("./../../../src/pages/foundations/typography.js" /* webpackChunkName: "component---src-pages-foundations-typography-js" */),
  "component---src-pages-foundations-writing-guidelines-js": () => import("./../../../src/pages/foundations/writing-guidelines.js" /* webpackChunkName: "component---src-pages-foundations-writing-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patterns-index-js": () => import("./../../../src/pages/patterns/index.js" /* webpackChunkName: "component---src-pages-patterns-index-js" */),
  "component---src-templates-component-details-js": () => import("./../../../src/templates/component-details.js" /* webpackChunkName: "component---src-templates-component-details-js" */),
  "component---src-templates-development-page-js": () => import("./../../../src/templates/development-page.js" /* webpackChunkName: "component---src-templates-development-page-js" */),
  "component---src-templates-pattern-details-js": () => import("./../../../src/templates/pattern-details.js" /* webpackChunkName: "component---src-templates-pattern-details-js" */)
}

